import {
  type FlagComponent,
  NL,
  BE,
  DE,
  FR,
  AT,
  ES,
  HU,
  IT,
  LU,
  PL,
  PT,
  RO,
  SE,
  SI,
  SK,
  CH,
  GB,
} from "country-flag-icons/react/3x2";

/**
 * These are the countries which are supported in the website. Please use this
 * instead of keys of {@link COUNTRY_MAP} to provide better readability.
 */
export const SUPPORTED_COUNTRIES = [
  // Full Support
  "NL",
  "BE",
  "DE",
  "FR",
  // Partial Support
  "AT",
  "ES",
  "HU",
  "IT",
  "LU",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  "CH",
  "GB",
] as const;

export const COUNTRY_CODES = SUPPORTED_COUNTRIES.map(key =>
  key.toLocaleLowerCase()
);

export type COUNTRY_ID = (typeof SUPPORTED_COUNTRIES)[number];

const withSize = (flag: FlagComponent) => {
  // Set default width and height to 16x12 to match the default size of the
  // country-flag-icons package.
  // @ts-expect-error - We are setting the default props here. Not sure how to
  // make typescript understand that.
  flag.defaultProps = { width: 16, height: 12 };
  return flag;
};

/**
 *  This is the map of all country related data. This map can contain data for
 *  more info than {@link SUPPORTED_COUNTRIES}.
 */
export const COUNTRY_MAP: Record<
  COUNTRY_ID,
  {
    flag: FlagComponent;
  }
> = {
  NL: { flag: withSize(NL) },
  BE: { flag: withSize(BE) },
  DE: { flag: withSize(DE) },
  FR: { flag: withSize(FR) },
  AT: { flag: withSize(AT) },
  ES: { flag: withSize(ES) },
  HU: { flag: withSize(HU) },
  IT: { flag: withSize(IT) },
  LU: { flag: withSize(LU) },
  PL: { flag: withSize(PL) },
  PT: { flag: withSize(PT) },
  RO: { flag: withSize(RO) },
  SE: { flag: withSize(SE) },
  SI: { flag: withSize(SI) },
  SK: { flag: withSize(SK) },
  CH: { flag: withSize(CH) },
  GB: { flag: withSize(GB) },
};
